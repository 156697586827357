import React from 'react'
import { graphql, Link } from 'gatsby'
import Slider from 'react-slick'

import Layout from '../components/layout'
import SEO from '../components/seo'
import PageHeader from '../components/page-header'

const SingleImageSlider = ({ images }) => {
  const sliderSettings = {
    className: 'product-image-slider',
    infinite: true,
    slidesToShow: 1,
    swipeToSlide: true,
    slidesToScroll: 1,
    dots: true,
    dotsClass: 'slider slider-thumb',
    customPaging: function(i) {
      return (
        <div
          className="slider slider-thumb slider-thumb-image"
          style={{ backgroundImage: `url(${images[i].file.url})` }}></div>
      )
    },
    draggable: true,
  }

  return (
    <Slider {...sliderSettings}>
      {images.map((image) => (
        <div key={image.file.url}>
          <div
            style={{ backgroundImage: `url(${image.file.url})` }}
            className="product-image-slider-img"
          />
        </div>
      ))}
    </Slider>
  )
}

export const MultipleImageSlider = ({ products }) => {
  const sliderSettings = {
    className: 'products-image-slider',
    dots: false,
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  return (
    <Slider {...sliderSettings}>
      {products.map(({ imagesLq, name, slug }) => (
        <Link key={name} to={`/products/${slug}`} className="products-image-slider-link">
          <div
            style={{ backgroundImage: `url(${imagesLq[0].file.url})`, height: '200px' }}
            className="products-image-slider-img">
            <div className="products-image-slider-name">
              <h3 className="font-weight-bold text-white">{name}</h3>
            </div>
          </div>
        </Link>
      ))}
    </Slider>
  )
}

export default (props) => {
  const { products: allProducts } = props.data.allContentfulPageProductsModal.nodes[0]
  const { slug: selectedSlug } = props.pageContext
  const selectedProduct = allProducts.find((project) => project.slug === selectedSlug)
  const indexOfSelectedProduct = allProducts.findIndex((project) => project.slug === selectedSlug)
  const lenghtOfAllProducts = allProducts.length
  const remainingProducts = allProducts.filter((project) => project.slug !== selectedSlug)

  const nextProduct =
    indexOfSelectedProduct + 1 === lenghtOfAllProducts
      ? null
      : allProducts[indexOfSelectedProduct + 1]
  const prevProduct = indexOfSelectedProduct === 0 ? null : allProducts[indexOfSelectedProduct - 1]

  const {
    name,
    description: { description },
    catalogue: {
      file: { url: catalogueURL },
    },
    imagesHQ,
  } = selectedProduct

  return (
    <Layout pageURL={''}>
      <SEO title={name} />
      <main>
        <PageHeader pageTitle={name} />
        <section className="container-fluid mt-5">
          <div className="row justify-content-between">
            <div className="col-md-2 align-self-center col-0 d-md-flex d-none justify-content-center text-center">
              {prevProduct !== null ? (
                <Link
                  to={`/products/${prevProduct.slug}`}
                  title={`Go to previous product`}
                  className="text-decoration-none">
                  <i className="fas fa-arrow-alt-circle-left font-size-10 pb-2" />
                  <p className="text-grey-5">{prevProduct.name}</p>
                </Link>
              ) : (
                <div className="align-self-center">&#x26AC;</div>
              )}
            </div>
            <div className="col-md-3 col-sm-6">
              <SingleImageSlider images={imagesHQ} />
            </div>
            <div className="col-md-3 col-sm-6">
              <h1 className="font-weight-bold mb-4">{name}</h1>
              <p className="my-5">{description}</p>
              <div className="row align-content-center">
                <div className="col-md-6">
                  <a
                    id="view-cat"
                    className="btn btn-lg gradient-background text-white border-0 text-uppercase font-size-4 py-3"
                    href={`https:${catalogueURL}`}
                    target="_blank"
                    rel="noopener noreferrer">
                    View catalogue
                  </a>
                </div>
                <div className="col-md-6 align-self-center">
                  <Link
                    id="view-cat2"
                    to="/products"
                    className="btn btn-light font-size-4 py-2 text-purple">
                    View all products
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-2 align-self-center text-center col-0 d-md-flex d-none text-center justify-content-center">
              {nextProduct !== null ? (
                <Link
                  to={`/products/${nextProduct.slug}`}
                  title={`Next : ${nextProduct.name}`}
                  className="text-decoration-none">
                  <i className="fas fa-arrow-alt-circle-right font-size-10 pb-2" />
                  <p className="text-grey-5">{nextProduct.name}</p>
                </Link>
              ) : (
                <div className="arrow-page arrow-page-right">&#x26AC;</div>
              )}
            </div>
          </div>
        </section>
        <section className="container-fluid mt-9 mb-5 border-top pt-5 pb-3">
          <div className="row">
            <div className="col-12">
              <h3 className="mbb-4 text-center">Discover other products</h3>
              <MultipleImageSlider products={remainingProducts} />
            </div>
          </div>
        </section>
      </main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query AllProductsPageQuery {
    allContentfulPageProductsModal {
      nodes {
        pageTitle
        subTitle
        products {
          description {
            description
          }
          catalogue {
            file {
              url
            }
          }
          name
          imagesHQ {
            file {
              url
            }
          }
          imagesLq {
            file {
              url
            }
          }
          slug
        }
      }
    }
  }
`
